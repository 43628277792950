import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesArti: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifacts guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_artifacts.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Artifacts guide</h1>
          <h2>
            The best Artifacts you can use in AFK Journey and where they shine.
          </h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifacts" />
        <StaticImage
          src="../../../images/afk/generic/beginner_23.webp"
          alt="Guides"
        />
        <p>
          Artifacts make a return in AFK Journey, but the system has been
          drastically changed compared to the original here. The biggest
          difference is that Artifact's aren't tied to characters anymore and
          instead you select one that's going to be used in the battle - either
          once or periodically throughout the battle.
        </p>
        <h5>Season</h5>
        <p>
          The check the information about the current Seasonal artifacts, go
          here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Artifacts - Season 2"
            link="/afk-journey/guides/artifacts-season"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_arti_s.jpg"
                alt="Artifacts - Season 2"
              />
            }
          />
        </Row>
        <h5>Base game</h5>
        <p>
          There are <strong>6 Artifacts</strong> currently available in the base
          game and you unlock them as you progress thought the main story.
        </p>
        <h5>TL;DR What base game Artifact is the best?</h5>
        <p>
          Confining Spell &gt; Starshard Spell &gt; Enlightening Spell &gt;
          Awakening Spell &gt; Blazing Spell &gt; Ironwall Spell
        </p>
        <p>And here's more information about the Artifacts:</p>
        <div className="artifacts">
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_5.webp"
                alt="Guides"
              />
            </div>
            <h5>Awakening Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                <strong>5s</strong> after the battle starts, summons Radiant
                Life to restore <strong>3</strong> weakest allies heroes Max HP
                by <strong>7%</strong> every <strong>10s</strong>.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> Increases the healing amount to{' '}
                  <strong>10%</strong> of Max HP.
                </li>
                <li>
                  <strong>At +8:</strong> The number of allies to receive this
                  buff increases to <strong>4</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  ATK <strong>+4.2%</strong>.{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+26.4%</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Heals your team every 10s. Usable in very aggressive teams that
                forgo healing altogether or rely on low-healing compositions
                such as Rowan or Shakir solo sustain.
              </p>
              <p>
                <strong>
                  Especially useful in Arcane Labyrinth and Legend Trial.
                </strong>
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Starter Artifact</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_2.webp"
                alt="Guides"
              />
            </div>
            <h5>Enlightening Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                At the start of a battle, the ATK SPD of 1 rearmost allied hero
                in the back is increased by <strong>80</strong> for{' '}
                <strong>15s</strong>. While the skill takes effect, the buffed
                allied hero gains control immunity.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> The bonus ATK SPD is increased to{' '}
                  <strong>100</strong>.
                </li>
                <li>
                  <strong>At +8:</strong> The effect duration is increased to{' '}
                  <strong>20s</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  ATK SPD <strong>+7.2</strong>{' '}
                </li>
                <li>
                  HP <strong>+11.4%</strong>
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Makes your backline hero attack faster for a few seconds at the
                start of the battle. Good in theory, but this effect is special
                in that it doesn't stack with other buffs, so if you have Lyca
                in your team her ATK SPD buff will be wasted, and the effect by
                itself ends up not being all that impactful overall.
              </p>
              <p>
                <strong>
                  Can be used in Arena to counter Confining Spell.
                </strong>
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Main story</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_1.webp"
                alt="Guides"
              />
            </div>
            <h5>Starshard Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Every <strong>4</strong> Ultimate cast by allies creates a flame
                wave that attacks all enemies, dealing true damage equal to{' '}
                <strong>16%</strong> of their current HP and up to 60% of Team
                ATK. The flame wave also reduces their ATK SPD by{' '}
                <strong>60</strong> for <strong>4s</strong>.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> Increases the damage to{' '}
                  <strong>24%</strong> of the current HP.
                </li>
                <li>
                  <strong>At +8:</strong> Triggered after allies use{' '}
                  <strong>3</strong> Ultimates.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  Haste <strong>+4.8</strong>{' '}
                </li>
                <li>
                  HP <strong>+10.2%</strong>
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Reduces enemy ATK SPD and deals HP% damage every 4/3 ults. A
                more aggressive option you pick if you feel you don't have
                enough damage with Confining Spell, takes slightly longer to
                come online. Good against tanky, healing-heavy turtle teams.
              </p>
              <p>
                <strong>Good in Arena and AFK Stages. Best for bosses,</strong>{' '}
                works pretty much everywhere due to the Haste buff, good as long
                as you can survive long enough to get enough Ults out to
                activate its effect.
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Main story</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_3.webp"
                alt="Guides"
              />
            </div>
            <h5>Ironwall Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Blesses the frontmost allied hero at the start of a battle,
                increasing their Phys DEF and Magic DEF by <strong>15%</strong>{' '}
                and Energy on Hit by <strong>20</strong> for the rest of the
                battle. At the start of a battle and every <strong>12s</strong>{' '}
                thereafter, grants blessed ally a shield by <strong>20%</strong>{' '}
                of their max HP for <strong>6s</strong>. The blessing can't be
                dispelled.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> The value of gained Phys DEF and Magic
                  DEF of blessed allied hero is increased to{' '}
                  <strong>20%</strong>.
                </li>
                <li>
                  <strong>At +8:</strong> The blessing effect will be
                  transferred to a living allied hero in the frontline when the
                  blessed hero is defeated. The blessing can only be transferred
                  <strong>1</strong> time in each battle.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  Haste <strong>+6</strong>{' '}
                </li>
                <li>
                  Vitality <strong>+9</strong>
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Makes your frontline hero tankier. A good option if you haven't
                managed to get a good amount of dupes for your tank hero yet,
                since if your tank dies the rest of your team dies shortly
                after.
              </p>
              <p>
                Most useful early on if you don't have a good tank, or any tank.{' '}
                <strong>
                  Used for AFK Stages/Campaign/Dream Realm/Legend Trial.
                </strong>
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Main story</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_6.webp"
                alt="Guides"
              />
            </div>
            <h5>Confining Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                <strong>3s</strong> into a battle, deals magic damage equal to{' '}
                <strong>25%</strong> of the team's ATK to <strong>2</strong>{' '}
                enemies in the back, making them unable to move or act for{' '}
                <strong>1.5s</strong>. Repeats the attack every every{' '}
                <strong>12s</strong>.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> The Prison duration is increased to{' '}
                  <strong>2.5s</strong>.
                </li>
                <li>
                  <strong>At +8:</strong> The cooldown of this ability is
                  reduced to <strong>10s</strong>.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  ATK <strong>+6.6%</strong>{' '}
                </li>
                <li>
                  HP <strong>+7.8%</strong>
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Locks down enemy backline and also deals damage to them. This
                will be your first pick of artifact when tackling a new stage.
                Good against squishy, backline-heavy teams.
              </p>
              <p>
                <strong>
                  Used basically everywhere - Arena, AFK Stages/Campaign, Battle
                  Drills, Legend Trial.
                </strong>
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Main story</strong>
            </p>
          </div>
          <div className="column">
            <div className="artifact-header">
              <StaticImage
                src="../../../images/afk/generic/arti_4.webp"
                alt="Guides"
              />
            </div>
            <h5>Blazing Spell</h5>
            <div className="artifact-description">
              <p className="sub">Description:</p>
              <p>
                Shoots a fireball at the weakest enemy every <strong>5s</strong>
                , dealing magic damage equal to <strong>20%</strong> of the Team
                ATK.
              </p>
              <ul>
                <li>
                  <strong>At +4:</strong> The damage dealt is increased to{' '}
                  <strong>30%</strong> of the Team ATK.
                </li>
                <li>
                  <strong>At +8:</strong> Deals extra damage equal to{' '}
                  <strong>4%</strong> of the target's lost HP.
                </li>
              </ul>
            </div>
            <div className="artifact-stats">
              <p className="sub">Stats (at +10):</p>
              <ul>
                <li>
                  DEF Penetration <strong>+7.8</strong>{' '}
                </li>
                <li>
                  Phys / Magic DEF <strong>+13.8%</strong>
                </li>
              </ul>
            </div>
            <div className="artifact-review">
              <p className="sub">Usage:</p>
              <p>
                Deals damage to the weakest enemy every 5s. Effect is too weak
                to matter much over the course of a battle. Just run Starshard
                Spell instead if you need the extra damage, or run Confining
                Spell if you want to go after enemy squishy backline.
              </p>
            </div>
            <p className="unlock">
              Unlocks: <strong>Main story</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesArti;

export const Head: React.FC = () => (
  <Seo
    title="Artifacts guide | AFK Journey | Prydwen Institute"
    description="The best Artifacts you can use in AFK Journey and where they shine."
    game="afk"
  />
);
